.project-details-name {
  color: #212a2f;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 30px;
}

.show-image {
  display: flex;
  justify-content: center;
  opacity: 0.8;
}

.show-image:hover {
  opacity: 1;
}


.show-project-links{
  display: flex;
  color: black;
}

.show-project-links, .view-code{
  display: flex;
  justify-content: center;
  background-color: #212a2f;

  width: 250px;
  margin-bottom: 20px;
}

.show-project-links:hover, .view-code:hover{
  display: flex;
  justify-content: center;
  background-color: rgb(138, 180, 138); 
  width: 250px;
  margin-bottom: 20px;
}