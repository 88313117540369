nav {
  position: fixed;
  width: 100%;
  background-image: linear-gradient(180deg, #212a2f, rgb(77, 85, 89));
  color: whitesmoke;
  top: 0;
  left: 0;
}

.nav {
  display: flex;
  margin-left: -2px;
  margin-right: -2px;
  margin-top: -2px;
  box-shadow: 1px 1px 1px 0.3px #cecece;
  width: 100%;
}

.logo {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 34px;
  letter-spacing: -3px;
  flex: 1;
  transition: 250ms all;
}

.logo:hover {
  transform: scale(1.004);
}

.links {
  align-items: center;
  font-size: 24px;
  display: flex;
  text-decoration: none;
}

a {
  color: whitesmoke;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}
a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: whitesmoke;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
a:hover:after {
  width: 100%;
  left: 0;
}

@media only screen and (max-width: 375px) {
  nav {
    position: fixed;
    max-width: 375px;
    background-image: linear-gradient(180deg, #212a2f, rgb(77, 85, 89));
    color: whitesmoke;
    top: 0;
    left: 0;
  }

  .nav {
    display: flex;
    margin-left: -2px;
    margin-right: -2px;
    margin-top: -2px;
    box-shadow: 1px 1px 1px 0.3px #cecece;
    width: 100%;
  }

  .logo {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 34px;
    letter-spacing: -3px;
    flex: 1;
    transition: 250ms all;
  }

  .logo:hover {
    transform: scale(1.004);
  }

  .links {
    align-items: center;
    font-size: 24px;
    display: flex;
    text-decoration: none;
  }

  a {
    color: whitesmoke;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
  }
  a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: whitesmoke;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after {
    width: 100%;
    left: 0;
  }
}
