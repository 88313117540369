.layout {
  height: 900px;
  display: flex;
  flex-direction: column;
}

.layout-children {
  flex: 2;
  padding: 100px;
  }

