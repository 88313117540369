.portfolio-header {
  color: #212a2f;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.portfolio{
  color: #212a2f;
  font-weight: 600;
  font-size: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin-bottom: 20px;
}

.flip-card {
  display: inline-block;
  background-color: transparent;
  width: 300px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1.2s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #212a2f;
  color: white;
  transform: rotateY(180deg);
  max-width: 350px;
}

img{
  display: flex;
  justify-content: center;
  width: 300px;
  height: 250px;
  background-color: whitesmoke;
  border: 2px solid lightgray;
}