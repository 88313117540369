.contact-form {
  display: flex;
  flex-direction: column;
  width: 500px;
  border: 2px solid lightgray;
  box-shadow: 5px 5px 5px #212a2f;
}



.form-group-one{
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.headshot{
  display: flex;
  justify-content: center;
  opacity: .9;


}

.headshot:hover{
  opacity:1;
}

.my-name-is-neo{
  display: inline;
  font-weight: 600;
}

.submit-button{
  display:flex;
  justify-content: center;
  margin-top: 10px;
}

button{
  background-color: #212a2f;
  color: whitesmoke;
  width: 150px;
  padding: 5px;
  font-weight: 600;

}

button:hover{
  background-color: #36657e;
  color: whitesmoke;
  width: 125px;
  font-weight: 600;
  transform: scale(1.02);

}

.resume{
  display: flex;
  justify-content: center;
  background-color: #212a2f;
  max-width: 575px;
  margin-top: 20px;
}

.resume:hover{
  background-color: #36657e;
  color: #212a2f;
}
.profile{
  display: flex;
  justify-content: center;
}